/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
/* @import "@ionic/angular/css/palettes/dark.system.css"; */

:root {
  --ion-color-primary: #f47421;
  --ion-color-primary-rgb: 244, 116, 33;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0255, 255, 255;
  --ion-color-primary-shade: #d7661d;
  --ion-color-primary-tint: #f58237;

  --ion-color-secondary: #21aa4a;
  --ion-color-secondary-rgb: 33, 170, 74;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1d9641;
  --ion-color-secondary-tint: #37b35c;

  --ion-color-tertiary: #646464;
  --ion-color-tertiary-rgb: 100, 100, 100;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #585858;
  --ion-color-tertiary-tint: #747474;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 240, 65, 65;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 250, 250, 250;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /* Set the background of the entire app */
  --ion-background-color: #f5f5f5;
  --ion-toolbar-background: #ffffff;
  //--ion-tab-bar-background: #FFFFFF;

  /* Set the font family of the entire app */
  --ion-font-family: 'Rubik', -apple-system, BlinkMacSystemFont,
    'Helvetica Neue', 'Roboto', sans-serif;

  --ion-card-color: #2b343b;
  --ion-card-background: #ffffff;

  --ion-font-heading: var(--ion-font-family);
  --ion-font-heading-1: 500 32px/40px var(--ion-font-heading);
  --ion-font-heading-2: 500 24px/28px var(--ion-font-heading);
  --ion-font-heading-3: 500 16px/22px var(--ion-font-heading);
  --ion-font-heading-4: 500 1.75rem/2rem var(--ion-font-heading);
  --ion-font-heading-5: 500 1.5rem/1.75rem var(--ion-font-heading);
  --ion-font-heading-6: 500 1.25rem/1.5rem var(--ion-font-heading);
  --ion-font-text: var(--ion-font-family);
  --ion-font-text-xl: normal 1.1875rem/1.75rem var(--ion-font-text);
  --ion-font-text-l: normal 1.0625rem/1.75rem var(--ion-font-text);
  --ion-font-text-l-2: normal 1.0625rem/1.5rem var(--ion-font-text);
  --ion-font-text-m: normal 14px/24px var(--ion-font-text);
  --ion-font-text-m-2: normal 1rem/1.25rem var(--ion-font-text);
  --ion-font-text-s: normal 12px/18px var(--ion-font-text);
  --ion-font-text-s-2: normal 0.8125rem/1rem var(--ion-font-text);
  --ion-font-text-xs: normal 0.6875rem/1rem var(--ion-font-text);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font: var(--ion-font-heading-1);
}

h2 {
  font: var(--ion-font-heading-2);
}

h3 {
  font: var(--ion-font-heading-3);
}

p {
  font: var(--ion-font-text-m);
}

ion-card {
  border-radius: 24px;
  box-shadow: 0 25px 40px 0 rgba(0, 0, 0, 0.1);

  ion-card-header,
  ion-card-content {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
    padding-top: 24px;
    padding-bottom: 20px;
  }
}

.header-md,
.footer-md {
  box-shadow: none;
}

ion-button {
  min-height: 48px;
  --border-radius: 12px !important;
}

.ion-color-medium {
  color: var(--ion-color-dark) !important;
}

.custom-input {
  --border-radius: 12px !important;
  text-align: left;
}

ion-label.label-stacked {
  display: inline-block;
  margin-bottom: 10px !important;
  padding: 0 6px;
  font-weight: 500;
  font-size: 21px;
}

ion-input {
  --border-color: #c9d0d9 !important;
  font-size: 15px;
}

ion-segment {
  --background: var(--ion-color-medium);
  padding: 4px;
  border-radius: 8px;

  ion-segment-button {
    font-size: 14px;
    min-height: 36px;
    --border-radius: 4px;

    &.segment-button-checked {
      color: var(--ion-color-primary);
    }
  }
}

ion-searchbar {
  --background: #eef2f6 !important;
  .searchbar-input-container {
    min-height: 44px !important;
    .searchbar-input {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      padding-inline-start: 45px !important;
    }
    .searchbar-search-icon {
      inset-inline-start: 12px !important;
    }
  }
}

.loading-wrapper.sc-ion-loading-md {
  background: none;
  box-shadow: none;
  padding: 0;

  ion-spinner {
    width: 70px;
    height: 70px;
  }
}

ion-toolbar[mode='ios'] {
  --min-height: 64px;
  --padding-top: 20px;
  --padding-bottom: 20px;
  ion-title {
    font-size: 18px;
    font-weight: 500;
  }
}

ion-modal {
  --border-radius: 22px;
}


.container {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
